import { useEffect, useState } from "react";
import AOS from 'aos';
import CustomerLoginButton from "./CustomerLoginButton";
import { Link } from 'react-router-dom';

export default function Home() {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (

        <>
            <div className='h-20'></div>

            <section className='min-h-screen hero flex items-center'>
                <div className="container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto">
                    <div className='grid grid-cols-12'>
                        <div className="col-span-12 md:col-span-8 lg:col-span-6 xl:col-span-5 flex flex-col justify-center align-start">
                            <div className='flex flex-col align-start' data-aos={"fade-up"}>
                                <h2 className='heading1 text-start'>Get Out of Debt</h2>
                                <div className='flex justify-start'>
                                    <p className='heading2'>Stress-Free</p>
                                </div>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52  pt-4'></div>
                                <p className='text-white bannertext mt-10 mr-20  text-start'>
                                    IARC is a debt resolution platform that puts the customer first. Our goal is to put the customer first, and to take the worry and stress out of the debt resolution process
                                </p>
                            </div>
                            <CustomerLoginButton styling={'bg-white text-black mt-8'} />
                        </div>
                    </div>
                    <div className="hidden md:block md:col-span-7 banner">
                    </div>
                </div>
            </section>

            <section className="py-16 sm:py-14">
                <p className="md:text-4xl text-3xl font-bold text-center">IARC is your partner in starting your Repayment Journey</p>
                    <div className="flex mt-9">
                        <div className="flex flex-wrap w-full md:mx-7 mx-2 gap-5 md:justify-between items-center justify-center">
                                <div className="group h-[450px] w-[330px] [perspective:1000px]">
                                    <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-1000 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                                        <div className="absolute inset-0 h-full w-full rounded-xl [backface-visibility:hidden] overflow-hidden bg-black">
                                            <div className="flex items-center h-full mx-5 leading-10 flex-col">
                                               <div className="mt-20">
                                               <svg width="75" height="75" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.8">
                                                <path d="M20 10.5C20 16.5 12 22.5 12 22.5C12 22.5 4 16.5 4 10.5C4 8.37827 4.84285 6.34344 6.34315 4.84315C7.84344 3.34285 9.87827 2.5 12 2.5C14.1217 2.5 16.1566 3.34285 17.6569 4.84315C19.1571 6.34344 20 8.37827 20 10.5Z" stroke="white" stroke-width='1.2' stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M12 13.5C13.6569 13.5 15 12.1569 15 10.5C15 8.84315 13.6569 7.5 12 7.5C10.3431 7.5 9 8.84315 9 10.5C9 12.1569 10.3431 13.5 12 13.5Z" stroke="white" stroke-width='1.2' stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                                </svg>
                                               </div>
                                                <p className="text-[32px] font-bold text-center mt-10 leading-[60px] text-white">Start your Repayment Journey</p>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 h-full w-full rounded-xl px-6 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                                            <div className="flex justify-center ">
                                                <img src='/assets/map-pin.svg' className= "md:mt-4 w-16 mt-2" />
                                            </div>
                                            <p className="text-xl text-black font-bold mt-2">Start Repaying and Regain Control</p>
                                            <div className="flex flex-col items-center justify-center mt-7">
                                                <div className="flex w-full">
                                                    <div className="relative">
                                                        <span className="absolute left-0 -top-1 bg-[#EBF5FF] w-7 h-7 rounded-full border border-[#1F2A37] text-sm text-[#1F2A37] text-center inline-block">
                                                        <p className="mt-[2px]">1</p>
                                                        </span>

                                                        <div className="absolute left-3 top-7 h-[45px] border-l-2 border-dashed border-gray-400"></div>
                                                    </div>
                                                    <div className="ml-11 w-[100%] text-left -mt-1">
                                                        <p className="text-[#1F2A37] font-semibold text-base md:text-lg">Login to your account</p>
                                                    </div>
                                                </div>

                                                <div className="flex !mt-11 w-full">
                                                    <div className="relative">
                                                        <span className="absolute left-0 -top-1 bg-[#EBF5FF] w-7 h-7 rounded-full border border-[#1F2A37] text-sm text-[#1F2A37] text-center inline-block">
                                                        <p className="mt-[2px]">2</p>
                                                        </span>

                                                        <div className="absolute left-3 top-7 h-[45px] border-l-2 border-dashed border-gray-400"></div>
                                                    </div>
                                                    <div className="ml-11 w-[100%] text-left">
                                                        <p className="text-[#1F2A37] font-semibold text-base -mt-1 md:text-lg">Confirm Account Details</p>
                                                    </div>
                                                </div>

                                                <div className="flex mt-11 w-full">
                                                    <div className="relative">
                                                        <span className="absolute left-0 -top-1 bg-[#EBF5FF] w-7 h-7 rounded-full border border-[#1F2A37] text-sm text-[#1F2A37] text-center">
                                                            <p className="mt-[2px]">3</p>
                                                        </span>
                                                    </div>
                                                    <div className="ml-11 w-[100%] text-left">
                                                        <p className="text-[#1F2A37] font-semibold text-base -mt-1 md:text-lg">Start Payment Journey</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to='https://res.iarc.co.in/login' target='_blank'><button className="w-full bg-[#3F83F8] text-[16px] font-medium text-white p-2 rounded-md mt-9">Sign up</button></Link>
                                        </div>
                                    </div>
                                </div>

                                {/* second card */}
                                <div className="group h-[450px] w-[330px] [perspective:1000px]">
                                    <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-1000 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                                        <div className="absolute inset-0 h-full w-full rounded-xl [backface-visibility:hidden] overflow-hidden bg-black">
                                            <div className="flex items-center h-full mx-5 leading-10 flex-col">
                                                <div className="mt-20">
                                                    <svg width="70" height="70" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 44H24C26.1217 44 28.1566 44.8429 29.6569 46.3431C31.1571 47.8434 32 49.8783 32 52V64C32 66.1217 31.1571 68.1566 29.6569 69.6569C28.1566 71.1571 26.1217 72 24 72H20C17.8783 72 15.8434 71.1571 14.3431 69.6569C12.8429 68.1566 12 66.1217 12 64V44ZM12 44C12 39.2724 12.9312 34.5911 14.7403 30.2234C16.5495 25.8557 19.2012 21.8871 22.5442 18.5442C25.8871 15.2012 29.8557 12.5495 34.2234 10.7403C38.5911 8.93117 43.2724 8 48 8C52.7276 8 57.4089 8.93117 61.7766 10.7403C66.1443 12.5495 70.1129 15.2012 73.4558 18.5442C76.7988 21.8871 79.4505 25.8557 81.2597 30.2234C83.0688 34.5911 84 39.2724 84 44M84 44V64M84 44H72C69.8783 44 67.8434 44.8429 66.3431 46.3431C64.8429 47.8434 64 49.8783 64 52V64C64 66.1217 64.8429 68.1566 66.3431 69.6569C67.8434 71.1571 69.8783 72 72 72H76C78.1217 72 80.1566 71.1571 81.6569 69.6569C83.1571 68.1566 84 66.1217 84 64M84 64V72C84 76.2435 82.3143 80.3131 79.3137 83.3137C76.3131 86.3143 72.2435 88 68 88H48" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                                <p className="text-[32px] font-bold text-center mt-10 leading-[60px] text-white">Why are we contacting you?</p>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 h-full w-full rounded-xl px-6 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                                            <div className="flex justify-center">
                                                <img src='/assets/headset.svg' className="w-16 mt-4"/>
                                                
                                            </div>
                                            <div className="mt-4">
                                                <h1 className="text-black font-bold text-xl">We’re Here to Help You Take the Next Step</h1>
                                                <p className="mt-4 text-black text-sm text-left leading-6">We’re reaching out because we want to support you in managing your outstanding obligations. 
                                                </p>
                                                <p className=" text-black text-sm text-left leading-6">
                                                    Our goal is to provide you with the resources and guidance needed to start your repayment journey smoothly. 
                                                </p>
                                                <p className=" text-black text-sm text-left leading-6">
                                                    You can call us on <b>011-41199445</b> or write to us at <b>customersupport@iarc.co.in</b>
                                                </p>
                                            </div>
                                            <a href='https://wa.me/+918657050390?text=Yes' target='_blank'><button className="w-full bg-[#3F83F8] text-[16px] font-medium text-white p-2 rounded-md md:mt-6 mt-5">WhatsApp Us</button></a>
                                        </div>
                                    </div>
                                </div>

                                {/* Third Card */}

                                <div className="group h-[450px] w-[330px] [perspective:1000px]">
                                    <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-1000 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                                        <div className="absolute inset-0 h-full w-full rounded-xl [backface-visibility:hidden] overflow-hidden bg-black">
                                            <div className="flex items-center h-full mx-3 leading-10 flex-col">
                                                <div className="mt-24">
                                                    <svg width="55" height="55" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M43.9998 2.00029V20.0003M43.9998 20.0003H61.9998M43.9998 20.0003L61.9998 2.00029M61.9998 46.7603V55.7603C62.0032 56.5958 61.832 57.4228 61.4973 58.1883C61.1626 58.9539 60.6717 59.6411 60.056 60.2059C59.4404 60.7707 58.7135 61.2007 57.922 61.4684C57.1306 61.7361 56.2919 61.8355 55.4598 61.7603C46.2283 60.7572 37.3608 57.6027 29.5698 52.5503C22.3213 47.9443 16.1758 41.7988 11.5698 34.5503C6.49973 26.7239 3.34453 17.8133 2.3598 8.54029C2.28483 7.71069 2.38342 6.87457 2.6493 6.08516C2.91518 5.29576 3.34251 4.57036 3.9041 3.95515C4.46568 3.33995 5.14921 2.84842 5.91117 2.51186C6.67313 2.17529 7.49682 2.00108 8.3298 2.00029H17.3298C18.7857 1.98596 20.1972 2.50153 21.3011 3.45089C22.405 4.40025 23.126 5.71863 23.3298 7.16029C23.7097 10.0405 24.4141 12.8685 25.4298 15.5903C25.8334 16.6641 25.9208 17.831 25.6815 18.9529C25.4423 20.0748 24.8864 21.1046 24.0798 21.9203L20.2698 25.7303C24.5405 33.2409 30.7592 39.4596 38.2698 43.7303L42.0798 39.9203C42.8955 39.1137 43.9253 38.5578 45.0472 38.3186C46.1691 38.0793 47.336 38.1667 48.4098 38.5703C51.1316 39.5859 53.9596 40.2904 56.8398 40.6703C58.2971 40.8759 59.628 41.6099 60.5794 42.7328C61.5308 43.8556 62.0363 45.289 61.9998 46.7603Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                                <p className="text-[32px] font-bold text-center mt-10 leading-[60px] text-white">What will happen when you call us?</p>
                                            </div>
                                      </div>
                                        <div className="absolute inset-0 h-full w-full rounded-xl px-6 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                                            <div className="flex justify-center">
                                                <img src='/assets/Vector.svg' className="w-16 md:w-11 mt-3 md:mt-6"/>
                                            </div>
                                            <div className="mt-4">
                                                <h1 className="text-black font-bold text-xl">Clear Guidance, Real Solutions</h1>
                                                <div className="text-black mt-4">
                                                    <p className="font-bold text-sm text-left">We’ll Understand Your Situation</p>
                                                    <p className="text-left text-sm mt-1">We’ll listen to your needs and offer personalized advice.</p>
                                                    <p className="font-bold text-sm text-left mt-2"> We’ll Explore Repayment Options</p>
                                                    <p className="text-left text-sm mt-1">We’ll walk you through the available plans suited to your situation.</p>
                                                    <p className="font-bold text-sm text-left mt-2">We'll Guide you to the next step together.</p>
                                                    <p className="text-sm text-left mt-1">Together, we’ll finalize your repayment plan and get you started.</p>
                                                </div>
                                            </div>
                                            <a 
                                                href={`tel:01141199445`} 
                                                target='_blank'
                                            >
                                                <button className="w-full bg-[#3F83F8] text-[16px] font-medium text-white p-2 rounded-md md:mt-5 mt-5 flex justify-center">
                                                    <span>Call Us</span>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {/* fourth card */}

                                <div className="group h-[450px] w-[330px] [perspective:1000px]">
                                    <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-1000 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                                        <div className="absolute inset-0 h-full w-full rounded-xl [backface-visibility:hidden] overflow-hidden bg-black">
                                            <div className="flex items-center h-full mx-5 leading-10 flex-col">
                                                <div className="mt-20">
                                                    <svg width="75" height="75" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 84C8.00046 78.9639 9.18957 73.9989 11.4706 69.509C13.7517 65.0191 17.0603 61.1309 21.1273 58.1607C25.1943 55.1905 29.905 53.2222 34.8761 52.4158C39.8473 51.6094 44.9386 51.9878 49.736 53.52M60 32C60 43.0457 51.0457 52 40 52C28.9543 52 20 43.0457 20 32C20 20.9543 28.9543 12 40 12C51.0457 12 60 20.9543 60 32Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M67.1686 58.0009L70.7815 58.0009C71.1169 57.9993 71.4489 58.0796 71.7562 58.2367C72.0636 58.3937 72.3394 58.6241 72.5662 58.913C72.7929 59.202 72.9655 59.543 73.073 59.9145C73.1805 60.2859 73.2204 60.6794 73.1902 61.0699C72.7875 65.4021 71.5212 69.5634 69.4929 73.2195C67.6439 76.6211 65.1768 79.505 62.267 81.6665C59.1251 84.0458 55.548 85.5265 51.8255 85.9886C51.4925 86.0238 51.1568 85.9775 50.8399 85.8527C50.523 85.7279 50.2318 85.5274 49.9848 85.2639C49.7379 85.0003 49.5405 84.6796 49.4054 84.322C49.2703 83.9644 49.2004 83.5779 49.2001 83.187L49.2001 78.9635C49.1943 78.2803 49.4013 77.6179 49.7824 77.0999C50.1635 76.5818 50.6928 76.2434 51.2715 76.1478C52.4277 75.9696 53.563 75.639 54.6556 75.1623C55.0867 74.9729 55.5552 74.9319 56.0056 75.0442C56.4559 75.1565 56.8693 75.4174 57.1968 75.7959L58.7263 77.5838C61.7413 75.5797 64.2378 72.6614 65.9522 69.1368L64.4227 67.3489C64.0989 66.9661 63.8758 66.4828 63.7797 65.9564C63.6837 65.4299 63.7187 64.8822 63.8808 64.3783C64.2885 63.1011 64.5713 61.7739 64.7238 60.4223C64.8063 59.7385 65.101 59.1139 65.5518 58.6674C66.0025 58.221 66.5779 57.9837 67.1686 58.0009Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                                <p className="text-[32px] font-bold text-center mt-10 leading-[60px] text-white">Contact us</p>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 h-full w-full rounded-xl px-5 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                                            <div className="flex justify-center">
                                                <img src='/assets/user-round-search.svg' className="w-16 mt-2"/>
                                            </div>
                                            <div className="mt-2">
                                                <h1 className="text-black font-bold text-lg">Have questions or need assistance with your repayment? </h1>
                                                <p className="mt-2 text-black text-sm text-left leading-7">
                                                    Our team is ready to help. Contact us via phone, email, or through your account portal, and we’ll provide the support you need.
                                                </p>
                                                <div className="mt-2">
                                                    <div className='flex items-center'>
                                                        <img src='/assets/phone.svg' />
                                                        <p className="text-black text-sm ml-3 font-bold">011-41199445</p>
                                                    </div>
                                                    <div className='flex items-center mt-1'>
                                                        <img src='/assets/whatsapp_icon.svg' />
                                                        <p className="text-black text-sm ml-3 font-bold">+91-8657050390</p>
                                                    </div>
                                                    <div className='flex items-center mt-1'>
                                                        <img src='/assets/mail.svg' />
                                                        <p className="text-black text-sm ml-3 font-bold">customersupport@iarc.co.in</p>
                                                    </div>
                                                    <div className='flex items-center mt-1'>
                                                         <img src='/assets/globe.svg' />
                                                         <p className="text-black text-sm ml-3 font-bold">res.iarc.co.in</p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <Link to='https://res.iarc.co.in/login' target='_blank'><button className="w-full bg-[#3F83F8] text-[16px] font-medium text-white p-2 rounded-md mt-4 md:mt-5">Customer Log In</button></Link>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
            </section>


            <section className='min-h-screen'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto min-h-screen py-10 lg:py-0'>
                    <div className='grid grid-cols-11 min-h-screen'>
                        <div className='col-span-11 take lg:col-span-5 flex items-end lg:min-h-screen justify-center'>
                            <img
                                src='/assets/shutterstock_1725877684 1.png'
                                className='w-2/4 lg:w-full mx-auto'
                                alt='Resolve your debt'
                            />
                        </div>
                        <div className='hidden lg:block lg:col-span-1'></div>
                        <div className='col-span-11 lg:col-span-5 flex items-center lg:min-h-screen'>
                            <div data-aos={"fade-up"}>
                                <p className='text-black font-bold put text-[40px] text-start'>Customized Debt Relief</p>
                                <p className='text-black font-bold put text-[40px] text-start'>Solutions</p>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 pt-4'></div>
                                <div className='flex justify-center'>
                                    <p className='text-black with text-base mt-5 mb-5 md:mt-10 text-start'>
                                    Debt settlement is possible! At IARC, we help you live a debt-free life with our flexible and convenient payment plans customized to your unique needs.
                                    </p>
                                </div>
                                <CustomerLoginButton styling={'bg-black text-white mt-6'} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='newsec min-h-screen w-full flex items-center'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 md:col-span-8 lg:col-span-6 xl:col-span-5 flex flex-col justify-center align-start" data-aos={"fade-up"}>
                            <div className='flex flex-col align-start'>
                                <h2 className='heading1 text-start'>Put Yourself Back</h2>
                                <div className='flex justify-start'>
                                    <p className='heading2'>in Control</p>
                                </div>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 pt-4'></div>
                                <p className='text-white bannertext mt-10 mr-20 text-start'>
                                    With IARC, the resolution of debt can be simple and straightforward. We provide you with the tools and resources you need to regain control of your finances, repair your credit score, and improve your creditworthiness.
                                </p>
                            </div>
                            <CustomerLoginButton styling={'bg-white text-black mt-8'} />
                        </div>
                    </div>
                </div>
            </section>

            <section className='min-h-screen flex items-center'>
                <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                    <div className='grid grid-cols-11'>
                        <div className='col-span-11 take lg:col-span-4 xl:col-span-5 lg:px-6 2xl:px-0 flex items-center justify-center'>
                            <img src='/assets/home3.png' className='w-3/4 md:w-2/4 lg:w-full 2xl:w-3/4 mx-auto h-auto' alt='Customer-Centered Debt Resolution' />
                        </div>
                        <div className='lg:col-span-1'></div>
                        <div className='col-span-11 lg:col-span-6 xl:col-span-5 flex flex-col items-center' data-aos={"fade-up"}>
                            <div className='mt-20'>
                                <p className='text-black font-bold put text-[40px] text-start'>Revolutionary Digital Platform for Convenient Debt Management</p>
                                <div className='border-b-4 border-green-500 w-1/5 md:w-52 pt-4'></div>
                                <div className='flex justify-center'>
                                    <p className='text-black with text-base mt-4 md:mt-10 text-start mb-10'>Our revolutionary platform provides comprehensive debt management solutions tailored to your unique needs, no matter where you are in the debt resolution process. With our user-friendly interface and easy-to-use tools, managing your debt has never been simpler. Experience the convenience of managing your debt at your fingertips with our innovative digital platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}