import BODLayout from './bodLayout';


const BodJayant = () => {

    return (
        <BODLayout
            name='Mr. Jayanta Kumar Banerjee'
            about={
                <>
                    Jayanta Banerjee has over three decades of work experience and has worked in multiple leadership roles. He has spent most of his career in private equity and has a background in corporate finance. He is the Executive Vice Chairman of Florintree Advisors. Prior to that he co-founded a mid-market private equity fund management company in joint-venture with ASK Group. Before that, Jayanta worked with organizations such as ICICI Venture, Lehman Brothers, EY, ICICI Securities, Bank of America, and Siemens. He has served on the Board of Directors of multiple companies across industries. Jayanta holds a PGDM (MBA) from IIM Bangalore and a BEE from Jadavpur University.
                </>
            }
            img='/assets/jayan.png'
            designation='Nominee Director of Florintree Evolution Private Limited.'
        />
    )
}
export default BodJayant