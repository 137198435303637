import './App.css';


const legalHeir = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> Legal Heir Demise</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='name pb-10 font-bold underline'>Procedure for return of original movable/ immovable property documents to the Legal heirs- Demise of sole borrower/ joint borrowers</p>
                    <div className='pointss'>
                        <p className='mb-5'>IARC, in the event of demise of the solo borrower/ joint borrowers and upon full repayment of dues, shall release the original movable/ immovable property documents to the Legal heirs of the borrower, on submission of the following documents through e-mail at customersupport@iarc.co.in</p>
                        <ul className='list-decimal'>
                            <li className='mb-5'>Copy of the Death Certificate</li>
                            <li className='mb-5'>Photograph and KYC documents of all the claimants of Legal heirs</li>
                            <li className='mb-5'>IARC may call for succession certificate from legal heirs of deceased borrowers in cases where there are disputes, and all legal heirs do not join in indemnifying the company and where the company has a reasonable doubt about the genuineness of the claimant/s being the only legal heir/s of the deceased borrower. </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default legalHeir
