import './App.css';


const Recovery = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Recovery Agents</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen flex flex-col justify-center'>
                    <p className='text-2xl'>
                        List of authorised recovery agency(ies)/agent(s), resolution agency(ies)/agent(s), collection agency(ies)/agent(s):
                    </p>
                    <div className='overflow-x-auto w-full max-h-[50rem] overflow-y-scroll mt-16'>
                        <table className="table-auto recovery-agent ">
                            <thead>
                                <tr>
                                    <th className='lg:min-w-[250px] '>Sr.No</th>
                                    <th className='min-w-[250px]'>Name of agency</th>
                                    <th className='min-w-[300px]'>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Aman Enterprises</td>
                                    <td>SR NO 16,PLOT NO C6 SHAMBHU NAGAR MAIN ROAD SHAHANURWADI PARISAR AURANGABAD 431005</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>2</td>
                                    <td>ARYAN ASSOCIATES</td>
                                    <td>BIJAWAR NAKA SAGAR ROAD CHHATRAPUR MP 471001</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>B.k Associates</td>
                                    <td>NEAR PRIMARY SCHOOL MIG B-2/25 ASHIYANA PHASE -2 MORADABAD 244001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>4</td>
                                    <td>Balaji Financial Services</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>BIG BULL MANAGEMENT</td>
                                    <td>JADHUA MARKET MAHADEV COMPLEX JADHUA HAJIPUR VAISHALI BIHAR 844101</td>
                                </tr>

                                <tr className='bg-alt'>
                                    <td>6</td>
                                    <td>Bullet Point</td>
                                    <td>SCF 19 T.F PHASE 2 SAS NAGAR MOHALI PUNJAB 160055 </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>City Enterprises</td>
                                    <td>SCO-87,1 ST FLOOR SECTOR-40-C CHANDIGARH 160036</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>8</td>
                                    <td>Collekto</td>
                                    <td>FLAT NO 703 BASILA ACME OZONE GLADY ALWARES ROAD MANPADA THANE MUMBAI 400610</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Coll-Man Services</td>
                                    <td>NO.6 JAWAHARLAL NEHRU ROAD ANNA NAGAR WEST CHENNAI 600040</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>10</td>
                                    <td>DDC CONSULTANCY</td>
                                    <td>East</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Decode Services</td>
                                    <td>31/A,FIRST FLOOR OFFICE NO 2 SAI NIKETAN OLD MUMBAI PUNE ROAD NEAR ASHOK HOTEL DAPODI PHUGEWADI 411012</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>12</td>
                                    <td>Dev Finvest Advisory services pvt ltd</td>
                                    <td>FLAT NO SF-3 ILMAN PALACE GOKHALE VIHAR MARG HAZARTGANJ LUCKNOW 226001</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Doer Solutions</td>
                                    <td>21ST FLOOR KH NO632 AJABPUR KHURD NAYA GAON UTTARAKHAND 248001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>14</td>
                                    <td>EXCEPTION IT SOLUTION</td>
                                    <td>PLOT NO 222,2ND FLOOR NEAR AADHAR SEVA KENDRA ZONE-1 MP NAGAR BHOPAL 462042</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Galaxy Assets Consultancy</td>
                                    <td>VILL-BARBARIA,PO-JAGNNATHPUR,PS DUTTAPUKUR DIST-NORTH 24 PARGANAS WEST BENGAL 700126</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>16</td>
                                    <td>Girish Gaur And Company</td>
                                    <td>65 RAM BAGH COLONY NEAR NAUCHANDI THANA MEERUT 250004 UP</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>HAR SHIV ENTERPRISES</td>
                                    <td>NEAR HOLI CHOWK UPPER G/F A-47 HARI VIHAR UTTAM NAGAR HASTSAL VIHAR NEW DELHI WEST DELHI 110059</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>18</td>
                                    <td>Harsh Financial Services</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>HASIM BUSINESS SOLUTION</td>
                                    <td>CHAMEDIYA LAYOUT NAGPUR ROAD YAVATMAL 445001 MAHARASHATRA</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>20</td>
                                    <td>Internal Power Services</td>
                                    <td>SAI NATH NAGAR H-104 C-SECTOR KOLAR ROAD BHOPAL MADHYAPREADESH 462042</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>JAI BHAVANI AGENCY</td>
                                    <td>Closed</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>22</td>
                                    <td>Jay Mataji Enterprises</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Jmd Associates</td>
                                    <td>LAKHAN WALI GALI PRAKASH NAGAR NUNHAI AGRA 282006</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>24</td>
                                    <td>KEEPERS ASSOCIATES</td>
                                    <td>31/C,BASEMENT MOHAMMAD PUR NEAR BHIKAJI CAMA PALACE NEW DELHI 110066</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>Kishor Kumar H M</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>26</td>
                                    <td>Kubera Fibabcial Services</td>
                                    <td>202,2ND FLOOR CRYSTAL HEIGHTS,13 MADHAVDAS MAHARASHATRA 400014</td>
                                </tr> 
                                <tr>
                                    <td>27</td>
                                    <td>Law Marshal</td>
                                    <td>110-111 SWADESH BHAWAN PRESS COMPLEX A B ROAD INDORE MADHYA PRADESH 452011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>28</td>
                                    <td>MAA SHARDA ASSOCIATES</td>
                                    <td>1 ST FLOOR GARDEN HOMES BEHIND SUNIL HOSERIAY NEAR BHAWARTAL GARDEN NAPIER TOWN JABALPUR MP</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>Maitreyi Associates</td>
                                    <td>FIRST FLOOR HALL NO.1 37-38 NEW BHOPALPURA UDAIPUR RAJSTHAN 313001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>30</td>
                                    <td>MARK AGENCY MUMBAI</td>
                                    <td>705 TOPIWALA CENTER 7 TH FLOOR OFF S V ROAD NEAR GOREGAON RAILWAY STATION GOREGAON (W) MUMBAI 400062</td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>MH Collation </td>
                                    <td>162/A, White House, Kolkata 19 700039</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>32</td>
                                    <td>MHAKAL ASSOCIATE VIDISHA</td>
                                    <td>WARD NO. 38 GANDHI NAGAR AGYARAM COLONY VIDISHA</td>
                                </tr>
                                <tr>
                                    <td>33</td>
                                    <td>Noor Solutions</td>
                                    <td>EMPRESS MASJID OPPOSITE MILL GATE AYYUB HERITAGE 5 TH FLOOR MARWADI CHAWL NAGPUR 440018</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>34</td>
                                    <td>NV SOLUTIONS</td>
                                    <td>OFFICE NO-27,1ST FLOOR VP BLOCK PITAM PURA DELHI 110034</td>
                                </tr>
                                <tr>
                                    <td>35</td>
                                    <td>Orange Associates</td>
                                    <td>PLOT NO 153 KASHI NAGAR,NEAR SHATABDI SQR RING ROAD NAGPUR 440027</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>36</td>
                                    <td>PAWANPUTRA ASSOCIATES/ DABLA BROTHERS PVT. LTD.</td>
                                    <td>B-26 MAHALAXMI NAGAR JLN MARG JAIPUR 302017 RAJSTHAN</td>
                                </tr>
                                <tr>
                                    <td>37</td>
                                    <td>Pernimius Financial Technologies Private Limited </td>
                                    <td>FLAT NO 703 BASILA ACME OZONE GLADY ALWARES ROAD MANPADA THANE MUMBAI 400610</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>38</td>
                                    <td>PINTEL SERVICES PRIVATE LIMITED</td>
                                    <td>PLOT NO 651,T/F KH-21/2 KAKROLA HOUSING COMPLEX KAKROLA NEW DELHI 110078</td>
                                </tr>
                                <tr>
                                    <td>39</td>
                                    <td>Poniya Services</td>
                                    <td>E-585,PARSHWANATH TOWN SHIP PART-02 NR SHIVAJI CHOWK KRISHNA NAGAR NEW NARODA AHMEDABAD GUJRAT 382345</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>40</td>
                                    <td>Radiant Management</td>
                                    <td>7 SETER PALACE OPPOSITE BANK COLONY MERRUT 25002 UTTAR PRADESH</td>
                                </tr>
                                <tr>
                                    <td>41</td>
                                    <td>RM Associates</td>
                                    <td>16-11-511/D/21/4, SHALIVAHANA NAGAR DILSUKHNAGAR HYDERABAD-500036</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>42</td>
                                    <td>Romit Akaay Associate</td>
                                    <td>OFFICE NO 503,5TH FLOOR 78 BUSINESS PARK (BMC Market Buliding)STATION ROAD BHANDUP WEST MUMBAI 400078</td>
                                </tr>
                                <tr>
                                    <td>43</td>
                                    <td>RR ASSOCIATES.</td>
                                    <td>56-15-5A GROUND FLOOR NEAR SGS ASHRAM SEETHARAM NAGAR PATAMATA VIJAYWADA 520010</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>44</td>
                                    <td>RV Enterprises</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr>
                                    <td>45</td>
                                    <td>S.K Agencies</td>
                                    <td>H NO 237 PUNAWALE ROAD NEAR RAAGA EMPERIO HOTEL TATHWADE RAVET ROAD,TATHWADE,PUNE 411033</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>46</td>
                                    <td>SANGHVI FINANCIAL SERVICES</td>
                                    <td>717,SWANIK ARCADE NEAR VARDAN TOWER PRAGATI NAGAR TO K K NAGAR ROAD NARANPURA 380013 GUJRAT</td>
                                </tr>
                                <tr>
                                    <td>47</td>
                                    <td>SAPTAGIRI ASSOCIATES</td>
                                    <td>HNO 23-88/8,R K NAGAR OPP.SREE VENKATESHWARA FUNCTION HALL ANANDBAGH X ROADAS MALKAJGIRI HYDERABAD 500047</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>48</td>
                                    <td>Satguru Agencies</td>
                                    <td>AT SHOP NO 1,SHITAL PLAZA COMPLEX NEAR LAXMI GAS AGENCY'S RUKHMINI NAGAR AMARAVATI 444606</td>
                                </tr>
                                <tr>
                                    <td>49</td>
                                    <td>Satguru Enterprises</td>
                                    <td>AT SHOP NO 1,SHITAL PLAZA COMPLEX NEAR LAXMI GAS AGENCY'S RUKHMINI NAGAR AMARAVATI 444606</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>50</td>
                                    <td>SAVIOUR MANAGEMENT SERVIC</td>
                                    <td>NO 438/8 PH ROAD ARUMBAKKAM CHENNAI 600106</td>
                                </tr>
                                <tr>
                                    <td>51</td>
                                    <td>Shashi & Sons Capital Services LLP</td>
                                    <td>F 309 PNTC RADIO MIRCHI ROAD SATELLITE AHMEDABAD 380015</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>52</td>
                                    <td>SHIDDI VINAYAK</td>
                                    <td>SR NO 13 B RAHUL COLONY HOLE PLOT SATAVWADI HADAPSAR PUNE 411028</td>
                                </tr>
                                <tr>
                                    <td>53</td>
                                    <td>Shivmit Enterprises</td>
                                    <td>C/O USMAN KHAN CHARKHAMBA CHOWK NEAR JAIN MANDIR VAISHALI NAGAR NAGPUR 440017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>54</td>
                                    <td>SHREE BALAJI Associates Rajashtan</td>
                                    <td>AMRAPALI CIRCLE VAISHALI NAGAR JAIPUR RAJSTHAN 302021</td>
                                </tr>
                                <tr>
                                    <td>55</td>
                                    <td>Shree Ganraj Enterprises</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>56</td>
                                    <td>Shree Loan Consultancy </td>
                                    <td>1A/1A SR NO 151 FLAT NO 27 KANCHAN JUNGA APARTMENT MAGARPATTA MUNDHAWA ROAD PUNE 411028</td>
                                </tr>
                                <tr>
                                    <td>57</td>
                                    <td>Shree Mahadevi Associates</td>
                                    <td>AC-167 C 2ND FLOOR SHALIMAR BAGH DELHI 110088</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>58</td>
                                    <td>SHREE MHAKAL BHOPAL</td>
                                    <td>A 167 INFRONT OF DURGA MANDIR NEHRU MADHYAPRADESH 462003 BHOPAL</td>
                                </tr>
                                <tr>
                                    <td>59</td>
                                    <td>SHREE SAI ENTERPRISES</td>
                                    <td>PLOT NO 27 CHINTAMANI LAYOUT SHIVKRUPA NAGAR GHOGALI BESA ROAD NAGPUR 440037</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>60</td>
                                    <td>Shree Sai Samarth</td>
                                    <td>101 SHREE DATTA PRASAD NEAR TJSB BANK RAM MURTHI ROAD NAUPADA THANE WEST 400602</td>
                                </tr>
                                <tr>
                                    <td>61</td>
                                    <td>Shree services</td>
                                    <td>FLAT NO 104 B H TOWER  KAMPTEE ROAD AUTOMOTIVE CHOWK NAGPUR 26</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>62</td>
                                    <td>Shree siddhidhata Solutions Pvt Ltd </td>
                                    <td>49,3RD FLOOR PRAKASH MENSION DR B A ROAD DADAR EAST MUMBAI 400014</td>
                                </tr>
                                <tr>
                                    <td>63</td>
                                    <td>Shree swami samarth Enterprises</td>
                                    <td>SAI NIKETAN SR NO.31/1A FIRST FLOOR OLD MUMBAI PUNE HIGHWAY NEAR ASHOK HOTEL DAPODI PUNE 411012</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>64</td>
                                    <td>Shree Venkateshwara Business Solutions</td>
                                    <td>1/1,59TH ,CROSS 5 TH BLOCK VATAL NAGARAJ ROAD BASHYAM CIRCLE RAJAJINAGAR BANGLORE 560010</td>
                                </tr>
                                <tr>
                                    <td>65</td>
                                    <td>Shree Vinayak Enterprises</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>66</td>
                                    <td>Shri Shyam Associates</td>
                                    <td>KHAIR ROAD NEAR KHARESHWAR CHAURAHA APPOSITE SBI BANK SHIVALIK MARKET ALIGARH UP 202001</td>
                                </tr>
                                <tr>
                                    <td>67</td>
                                    <td>Shubham Debt Recovery Agency</td>
                                    <td>17L/13A/10K,OM NAGAR,RAJAPUR PRAYAGRAJ UP 211001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>68</td>
                                    <td>Siddhi Vinayak Enterprises</td>
                                    <td>202-203,2ND FLOOR,B METRO TOWER VIJAY NAGAR INDORE MADHYA PRADESH 452010</td>
                                </tr>
                                <tr>
                                    <td>69</td>
                                    <td>Siddhivinayak Services</td>
                                    <td>No Longer Working With IARC</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>70</td>
                                    <td>SK AGENCIES</td>
                                    <td>49-14-19 LALITHANAGAR AKKAYYAPALEM VISHAKHAPATNAM 530016</td>
                                </tr>
                                <tr>
                                    <td>71</td>
                                    <td>SRI ASSOCIATES</td>
                                    <td>AASHRYA 214,15th MAIN,24TH CROSS,B S K 2ND STAGE BANGLORE -560070</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>72</td>
                                    <td>Sri Kanakadurga Associates </td>
                                    <td>D NO-40-2-30-A SRI SAI APARTMENTS RAGHURAMAIAH STREET BESIDE SIDDHARTHA SCHOOL VIJAYWADA 520010</td>
                                </tr>
                                <tr>
                                    <td>73</td>
                                    <td>SRI LAKSHMI AGENCIES</td>
                                    <td>FLAT NO 502,PLOT NO-410 ROAD 2/2 MATRUSRI NAGAR MIYAPUR HYDERABAD TELANGANA 500049</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>74</td>
                                    <td>Sri Lalitha Financial Services</td>
                                    <td>D NO 76-17-528 URMILA SUBBARAO NAGAR BHAVANIPURAM VIJAYWADA NTR DISTRICT ANDHRA PRADESH 520012</td>
                                </tr>
                                <tr>
                                    <td>75</td>
                                    <td>SRI SAIRAM SERVICES</td>
                                    <td>H NO 9-145,PVN COLONY,MALAKAJGIRI,MEDCHAL TELANGANA 500047</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>76</td>
                                    <td>SRIVASTAVA & SONS</td>
                                    <td>2ND FLOOR REKHI MANSION DAIGONAL ROAD BISTUPUR JAMSHEDPUR JHARKHAND 831001</td>
                                </tr>
                                <tr>
                                    <td>77</td>
                                    <td>Srivastava And Sons Prop Binod Kumar Srivastava</td>
                                    <td>2ND FLOOR REKHI MANSION DAIGONAL ROAD BISTUPUR JAMSHEDPUR JHARKHAND 831001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>78</td>
                                    <td>SRM</td>
                                    <td>SHOP NO 03,C-17 KUNJ VIHAR JAGRITI VIHAR MEERUT UP 250004</td>
                                </tr>
                                <tr>
                                    <td>79</td>
                                    <td>SS SOLUTIONS</td>
                                    <td>55/A,CHINTU NILAYAM MADHURA NAGAR HYDERABAD 500038 TELANGANA</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>80</td>
                                    <td>Strength Enterprise </td>
                                    <td>Closed</td>
                                </tr>
                                <tr>
                                    <td>81</td>
                                    <td>SV ASSOCIATES</td>
                                    <td>1-10-130/8 SHASHAB GUTTA MAHABUBNAGAR 509001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>82</td>
                                    <td>The Links Associates</td>
                                    <td>Closed</td>
                                </tr>
                                <tr>
                                    <td>83</td>
                                    <td>Uniqe Associates</td>
                                    <td>A-72,2ND  FLOOR STREET NO-4,MADHU VIHAR LPEXTN DELHI 110092</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>84</td>
                                    <td>VGM</td>
                                    <td>GRM TECH BUILDING DH-6/29,NEWTOWN ACTION AREA-I,NORTH 24 PARGANAS 700156</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Recovery