import BODLayout from './bodLayout';
const BodSonal = () => {
    return (
        <BODLayout
            name='Ms. Sonal Shrivastava'
            about={
                <>
                   With over 27 years of rich experience in the finance and accounting profession, Ms. Sonal Shrivastava is the Ex-Group CFO of Vedanta and Ex Regional CFO Holcim for Asia Pacific, Middle East Africa covering 25 countries.
                    <br/>
                    <br/>
                    Ms. Shrivastava in her long career has led the overall finance and strategy functions in various organizations. She was also the Chief Financial Officer at Ambuja Cements Limited and has also held various operational roles in ACC Limited as Chief Supply Chain Officer and Head of Strategy & Projects.
                    <br/>
                    <br/>
                    In her vast career span of over two decades, Ms. Shrivastava was associated with many reputed companies in the field of Mining, Private Equity, Renewable Energy, such as Hindustan Zinc Limited, Duet India Infrastructure Pvt. Ltd., Suzlon Energy Ltd. (India) and Infrastructure Leasing & Financial Services Ltd. Additionally, she has also led International cross-functional teams in various fund raising, investments, M&A and business audit. Her core expertise includes Finance & Control, M&A, Strategy & Business Development, Capital Market fundraising, Purchase, Audit, Governance Risk & Compliance, Shared Services and more.
                    <br/>
                    <br/>
                    She has held various Board and Audit Committee positions for several companies across various geographies.
                    <br/>
                    Ms. Shrivastava has been associated with Holcim Group for over a decade in which she received an opportunity to work with Group’s various entities such as Lafarge Group SA- Paris, France and India, Lafarge India Private Limited, Ambuja Cements Limited and ACC Limited at different intervals.
                    <br/>
                    <br/>
                    Ms. Sonal Shrivastava holds educational accomplishments in Masters in Management Studies, Finance (MBA) from Jamnalal Bajaj Institute of Management Studies, Mumbai University, India and Bachelor of Engineering in Chemical Engineering from BIT Sindri, India. She was a member of CII National Committee on Financial Reporting 2021-22, and Board Member of various Group Companies. She is also the Executive Member of the CFO Board, a pre-eminent body of CFOs.
                    <br/>
                </>
            }
            img='/assets/so.png'
            designation='Independent Director'
        />
    )
}
export default BodSonal;