
import './App.css';
import AOS from 'aos';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'


function PolicyBox({
    img, heading, link, delay
}) {
    return (
        <Link to={link} className='flex items-center justify-center' data-aos='fade-up' data-aos-delay={delay}>
            <div className='policy-box'>
                <img src={img} alt={heading} />
                <p className='policies mt-2'>{heading}</p>
                <div className='mt-auto'>
                    <button className="bg-transparent butto hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded">
                        View details
                    </button>
                </div>
            </div>
        </Link>
    );
}

const Policy = () => {
    const [activeSection, setActiveSection] = useState('Policies');
    const toggleSection = (section) => {
        setActiveSection((prevSection) => (prevSection === section ? '' : section));
    };

    useEffect(() => {
        AOS.init({
            duration: 1000
        });

    }, []);


    return (

        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                Policies & Procedure
            </div>
            <section className='min-h-screen flex items-start bg-secondary'>
            <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                <div className='flex items-center gap-6 mt-10'>
                    <h2 className={`font-bold auction text-2xl cursor-pointer ${activeSection === 'Policies' ? 'text-green-500' : ''}`} onClick={() => toggleSection('Policies')}>Policies</h2>
                    <h2 className={`font-bold auction text-2xl cursor-pointer ${activeSection === 'Procedure' ? 'text-green-500' : ''}`} onClick={() => toggleSection('Procedure')}>Procedure</h2>
                </div>
                <div className="flex mt-3 mb-6">
                   {activeSection === 'Policies' && (
                        <div className="border-b-4 border-green-500 w-24 md:w-32"></div>
                    )}
                    {activeSection === 'Procedure' && (
                        <div className="border-b-4 border-green-500 w-32 md:w-40 ml-[11rem]"></div>
                    )}
                </div>
                 {activeSection === 'Policies' && (
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-10 gap-6'>
                        <PolicyBox
                            img='/assets/Frame 13.svg'
                            heading='Whistle Blower Policy'
                            link='/whistle-blower-policy'
                            delay='0'
                        />
                        <PolicyBox
                            img='/assets/Frame 14.svg'
                            heading='Fair Practice Code'
                            link='/fair-practice-code'
                            delay='200'
                        />
                        <PolicyBox
                            img='/assets/Frame 15.svg'
                            heading='CSR Policy'
                            link='/csr-policy'
                            delay='400'
                        />
                        <PolicyBox
                            img='/assets/Frame 16.svg'
                            heading='Anti Corruption Compliance Policy'
                            link='/anti-corruption-compliance-policy'
                            delay='600'
                        />
                        <PolicyBox
                            img='/assets/Frame 17.svg'
                            heading='Fraud Prevention & Detection Policy'
                            link='/fraud-prevention-detection'
                            delay='800'
                        />
                        <PolicyBox
                            img='/assets/Frame 18.svg'
                            heading='NR Policy'
                            link='/nr-policy'
                            delay='800'
                        />
                        <PolicyBox
                            img='/assets/code of conduct.svg'
                            heading='Code of Conduct for Directors and Senior Management'
                            link='/code-of-conduct'
                            delay='800'
                        />
                        <div></div>
                        <div></div>
                    </div>
                 )}
                  {activeSection === 'Procedure' && (
                     <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-10 gap-6 mt-6">
                         <PolicyBox
                             img="/assets/Frame 13.svg"
                             heading="Legal Heir Demise"
                             link="/legal-heir-demise"
                             delay="0"
                         />
                        <div></div>
                        <div></div>
                    </div>
                    )}
                </div>
            </section>
        </>
    )
}
export default Policy