import './App.css';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CustomerLoginButton from './CustomerLoginButton';

function disableScroll(e) {
	console.log(e);
	e.preventDefault();
}

export default function Header() {

	const loc = useLocation();
	const [isNavOpen, setIsNavOpen] = useState(false);

	useEffect(() => {
		if (isNavOpen) {
			window.addEventListener('touchmove', disableScroll, { passive: false });
		} else {
			window.removeEventListener('touchmove', disableScroll);
		}

		return (() => window.removeEventListener('touchmove', disableScroll));

	}, [isNavOpen]);

	const handleOpenZohoCareer = () => {
		window.open("https://iarc.zohorecruit.in/jobs/Careers", "_blank");
	}

	return (
			<div>
			<header className='head'>
				<nav className="bg-white px-2 xl:px-0 py-2.5 fixed w-full z-20 top-0 left-0 position-fixed">
					<div className="lg:w-full xl:w-4/5 2xl:container flex flex-wrap items-center justify-between mx-auto">
						<Link to="/" className="flex items-center">
							<img src="assets/iarc final logo.jpg" className="h-4 mr-3 sm:h-9 logo" alt="IARC Logo" />
						</Link>
						<div className="flex md:order-2">
						<CustomerLoginButton styling={'bg-black text-white'} />
							<div className='lg:hidden'>
								<span className="sr-only">Open main menu</span>
								<img
									src='/assets/icon-menu.png'
									className='w-6 h-auto mr-4'
									onClick={() => setIsNavOpen(!isNavOpen)}
									alt='menu'
								/>
							</div>
						</div>

						<div className="navitems items-center justify-between hidden w-full lg:flex md:w-auto md:order-1" id="navbar-sticky">
							<ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-4 lg:space-x-6 md:mt-0 text-sm md:font-medium md:border-0 md:bg-white">
								<li>
									<Link to="/" className={`${loc.pathname === '/' ? 'active' : ''} block`} aria-current="page">Home</Link>
								</li>
								<li>
									<Link to="/what-we-do" className={`${loc.pathname === '/what-we-do' ? 'active' : ''} block`}>What we do</Link>
								</li>
								<li>
									<Link to="/our-board" className={`${loc.pathname === '/our-board' ? 'active' : ''} block`}>Our Board</Link>
								</li>
								<li>
									<Link to="/assets-for-sale" className={`${loc.pathname === '/assets-for-sale' ? 'active' : ''} block`}>Assets for sale</Link>
								</li>
								<li>
									<Link to="/careers" className={`${loc.pathname === '/careers' ? 'active' : ''} block`} onClick={handleOpenZohoCareer}>Careers</Link>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>


			<section className={`mobile-menu flex lg:hidden bg-transparent  ${isNavOpen ? "showMenuNav" : "hideMenuNav"}`}>
				<div className='grow h-full bg-transparent close-section' onClick={() => setIsNavOpen(false)}></div>
				<div className={`menu ${isNavOpen ? "showMenuNav" : "hideMenuNav"}`}>
					<div
						className="absolute top-0 right-0 px-8 py-8"
						onClick={() => setIsNavOpen(false)}
					>
						<svg
							className="h-8 w-8 text-gray-600"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<line x1="18" y1="6" x2="6" y2="18" />
							<line x1="6" y1="6" x2="18" y2="18" />
						</svg>
					</div>
					<ul className="flex flex-col justify-between min-h-[250px]">
						<li>
							<Link
								to='/'
								className={`${loc.pathname === '/' ? 'active' : ''} block`}
								onClick={() => setIsNavOpen(false)}
							>
								Home
							</Link>
						</li>
						<li>
							<Link
								to="/what-we-do"
								onClick={() => setIsNavOpen(false)}
								className={`${loc.pathname === '/what-we-do' ? 'active' : ''} block`}
							>
								What we do
							</Link>
						</li>
						<li>
							<Link
								to="/our-board"
								onClick={() => setIsNavOpen(false)}
								className={`${loc.pathname === '/our-board' ? 'active' : ''} block`}
							>
								Our Board
							</Link>
						</li>
						<li>
							<Link
								to="/assets-for-sale"
								onClick={() => setIsNavOpen(false)}
								className={`${loc.pathname === '/assets-for-sale' ? 'active' : ''} block`}
							>
								Assets for sale
							</Link>
						</li>
						<li>
							<Link
								to="/careers"
								onClick={() => setIsNavOpen(false)}
								className={`${loc.pathname === '/careers' ? 'active' : ''} block`}
							>
								Careers
							</Link>
						</li>
						<li>
							<Link
								to='https://res.iarc.co.in/login' 
								target="_blank"
								onClick={() => setIsNavOpen(false)}
								className={`${loc.pathname === '/coming-soon' ? 'active' : ''} block`}
							>
								Resolution Portal
							</Link>
						</li>
					</ul>
				</div>
			</section>
			</div>
	);
}