import AOS from "aos";
import { useEffect } from 'react';
import './App.css';
import { Link, useLocation } from 'react-router-dom'


function InvestorCard({ img, name, designation, link, delay }) {
    return (
        <Link to={link} className='flex items-center justify-center' data-aos='fade-up' data-aos-delay={delay}>
            <div className='investor-boxes'>
                <div className='flex justify-center pb-6'>
                    <img src={img} alt='image' />
                </div>
                <p className='dirhead flex justify-center text-[18px] pb-6 text-center'>
                    {name}
                </p>
                <p className='designation flex justify-center text-center italic'>
                    {designation}
                </p>
            </div>
        </Link>
    );
}

const Directors = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <>
            <div className='h-20'></div>
            <div>
                <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Our Board
                </div>

                <section className='min-h-screen flex items-center'>
                    <div className='container px-6 lg:px-0 xl:w-4/5 2xl:container mx-auto'>
                        <h2 className='font-bold auction mb-5 mt-10'>Our Board</h2>
                        <div className='border-b-4 border-green-500 bor mb-10 w-1/5 md:w-52 mb-10'></div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-10 gap-6'>
                            {/* <InvestorCard
                                img='/assets/Ellipse 2harjit.png'
                                name='Mr. Harjit Singh Bhatia'
                                designation='Independent Director & Chairman of the Board'
                                link='/board-of-directors-harjit-singh-bhatia'
                                delay='100'
                            /> */}
                            {/* <InvestorCard
                                img='/assets/Ellipse 3meneschee.png'
                                name='Mr. Menes Chee'
                                designation='Nominee, Blackstone Group'
                                link='/board-of-directors-menes-chee'
                                delay='200'
                            /> */}
                            <InvestorCard
                                img='/assets/naveenjain.png'
                                name='Mr. Naveen Jain'
                                designation='Nominee Director of Florintree Evolution Private Limited.'
                                link='/board-of-directors-naveen-jain'
                                delay='300'
                            />
                            {/* <InvestorCard
                                img='/assets/Ellipse 2nikhil.png'
                                name='Mr. Nikhil Goyal'
                                designation='Nominee, Blackstone Group'
                                link='/board-of-directors-nikhil-goyal'
                                delay='400'
                            /> */}
                            <InvestorCard
                                img='/assets/Ellipse 3jimmy.png'
                                name='Mr. Jimmy Tata'
                                designation='Nominee, HDFC Bank  Ltd'
                                link='/board-of-directors-jimmy-tata'
                                delay='500'
                            />
                            <InvestorCard
                                img='/assets/Ellipse 2praveen.png'
                                name='Mr. Praveen Kadle'
                                designation='Independent Director'
                                link='/board-of-directors-praveen-kadle'
                                delay='600'
                            />
                            <InvestorCard
                                img='/assets/Ellipse 2vikas.png'
                                name='Mr. Vikas Nanda'
                                designation='Independent Director'
                                link='/board-of-directors-vikas-nanda'
                                delay='700'
                            />
                            <InvestorCard
                                img='/assets/inv.png'
                                name='Mr. Anjani Kumar'
                                designation='Independent Director'
                                link='/board-of-directors-anjani-kumar'
                                delay='700'
                            />
                            <InvestorCard
                                img='/assets/Ellipse 2neeraj.png'
                                name='Mr. Neeraj I. Mohan'
                                designation='Managing Director & Chief Executive Officer'
                                link='/board-of-directors-neeraj-mohan'
                                delay='800'
                            />
                            <InvestorCard
                                img='/assets/jayant.png'
                                name='Mr. Jayanta Kumar Banerjee'
                                designation='Nominee Director of Florintree Evolution Private Limited.'
                                link='/board_of_directors-jayanta_banrjee'
                                delay='800'
                            />
                            <InvestorCard
                                img='/assets/son.png'
                                name='Ms. Sonal Shrivastava'
                                designation='Independent Director'
                                link='/board_of_director-sonal-shrivastava'
                                delay='800'
                            />
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
}

export default Directors;